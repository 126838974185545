@tailwind base;
@tailwind components;
@tailwind utilities;


/* @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@600&family=Raleway:wght@500&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Fraunces&family=Quicksand:wght@600&family=Raleway:wght@500&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Fraunces&family=Quicksand:wght@600;700&family=Raleway:wght@500&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Fraunces&family=Quicksand:wght@400;500;600;700&family=Raleway:wght@500&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply h-full;

}
#root {
  @apply h-full;
}
/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


@media print {
  @page {
     padding: 10px;
  }
}



div[data-amplify-container] {
   @apply !w-full !h-full md:!w-[20%] md:!h-auto;
} 

 .amplify-button[data-variation='primary']{
  @apply !absolute !left-0 !right-0 !bottom-0 !rounded-none !p-3;
  @apply md:!relative md:!left-auto md:!right-auto md:!bottom-auto md:!rounded-full md:!p-2;
}

div[data-amplify-router]{

}

html{
  scroll-behavior: smooth;
  @apply h-full;
}
div[data-amplify-router]{
    @apply !w-full !h-full;
}



.shadow {
  -webkit-box-shadow: 0px 4px 8px 5px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 4px 8px 5px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 8px 5px rgba(0, 0, 0, 0.25);
}
.container_shadow{
  -webkit-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.16);
  -moz-box-shadow:0px 0px 5px 2px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.16);
}
.intro_shadow {
  -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
  -moz-box-shadow:0px 0px 3px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
}

.hidden_scrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0px;
}
.react-confirm-alert-body {
  @apply font-raleway text-sm whitespace-pre-line
}
.react-confirm-alert-body h1 {
  @apply text-2xl font-bold text-black mb-2;
}
.react-confirm-alert {
}

.action_container::-webkit-scrollbar {
  /* display: none; */
  /* display: block; */
  -webkit-appearance: none;
  width: 5px;
}

.action_container::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-color: rgba(0, 0, 0, .4);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .2);
}
.action_container {
  overflow: scroll;

  /* -ms-overflow-style: none; / IE and Edge */
  /* scrollbar-width: 1px;  Firefox */
  /* scrollbar-color: red; */
}



.action_container_br::-webkit-scrollbar {
  display: none;
}

.action_container_br {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}




.track_shadow {
  -webkit-box-shadow:0px 0px 2px 1px rgba(0, 0, 0, 0.25);
  -moz-box-shadow:0px 0px 2px 1px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25);
}
.intro_bg{
   background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
}

.slider_track {
  @apply rounded-full h-[0.4rem];
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
  /* background-image: url("./assets/bg/slider.png"); */

  /* background: linear-gradient(90deg, #E70909 0%, #F6AAA4 47.92%, #7DCFB6 100%); */
}
.slider_active {
  /* background: lawngreen */
  @apply rounded-full h-[0.45rem];
  background: linear-gradient(90deg, #E70909 0%, #F6AAA4 47.92%, #7DCFB6 100%);

  
}
/* w-4 h-4 rounded-full track_shadow outline-none ring-0 -top-[7px] border-[3px] border-br bg-btn ovr_rnd */
.ovr_rnd {
  @apply !w-5 !h-5 !cursor-pointer;
}



.slider_inverted_active {
  @apply rounded-full h-[0.45rem];
  background: linear-gradient(90deg, #A6EED7 16.14%, #D5CB75 52.81%, #D20019 91.03%);
}
.slider_inverted_green_active {
  @apply rounded-full h-[0.45rem];
  background: linear-gradient(-90deg, #A6EED7 16.14%, #D5CB75 52.81%, #D20019 91.03%);
}

.slider_inverted {
  @apply rounded-full h-[0.4rem];
  /* background: linear-gradient(90deg, #A6EED7 16.14%, #D5CB75 52.81%, #D20019 91.03%); */
}
.slider_inverted_green {
  @apply rounded-full h-[0.4rem];
  /* background: linear-gradient(-90deg, #A6EED7 16.14%, #D5CB75 52.81%, #D20019 91.03%); */
}

.font_bold {
  @apply font-bold;
}


.completed_cover {
@apply rounded-2xl;
background: linear-gradient(88.62deg, #008EA2 5.55%, #F38D85 95.89%);
box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
}
.link_text {
  @apply  text-dark-blue font-bold underline;
}

[data-amplify-authenticator] [data-amplify-form] { 
   @apply flex flex-col;
}
.auth_screen_bold_text {
  @apply text-xs font-semibold text-black pb-4 font-quick;
}
